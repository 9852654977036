import React, { useState } from "react";

function App() {
  const [account, setAccount] = useState("");
  const [retrievedAddress, setRetrievedAddress] = useState("");
  const [kaanchAcc, setkaanchAccount] = useState("");
  const [aptosAddress, setAptosAddress] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [isaptosConnected, setIsaptosConnected] = useState(false);
  const [number, setNumber] = useState("");
  const [toAddress, setToAddress] = useState("");
  const [contractAddress, setContractAddress] = useState("");

  const connectToMetaMask = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await window.ethereum.request({ method: "eth_accounts" });
        setAccount(accounts[0]);
      } else {
        alert("MetaMask not found. Please install it.");
      }
    } catch (error) {
      console.error(error);
      alert("Error connecting to MetaMask.");
    }
  };

  const retrieveAddress = async () => {
    if (window.kaanch && window.kaanch.connect) {
      const retrieved = await window.kaanch.connect();
      setRetrievedAddress(retrieved);
    } else {
      alert("The function window.kaanch.connect() is not available.");
    }
  };

  const checkIsConnected = async () => {
    if (window.kaanch && window.kaanch.isConnected) {
      const connected = await window.kaanch.isConnected();
      setIsConnected(connected);
    } else {
      alert("The function window.kaanch.isConnected() is not available.");
    }
  };

  const checkaptosConnected = async () => {
    if (window.aptos && window.aptos.isConnected) {
      const connected = await window.aptos.isConnected();
      setIsaptosConnected(connected);
    } else {
      alert("The function window.aptos.isConnected() is not available.");
    }
  };

  const retrieveKaanchAccount = async () => {
    if (window.kaanch && window.kaanch.account) {
      const kaanchAcc = await window.kaanch.account();
      setkaanchAccount(kaanchAcc);
    } else {
      alert("The function window.kaanch.account() is not available.");
    }
  };

  const disconnectFromKaanch = async () => {
    if (window.kaanch && window.kaanch.disconnect) {
      window.kaanch.disconnect();
      setIsConnected(false);
      setRetrievedAddress("");
    } else {
      alert("The function window.kaanch.disconnect() is not available.");
    }
  };

  const sendKaanch = async () => {
    if (window.kaanch && window.kaanch.sendKaanch) {
      // You should validate 'number' and 'toAddress' here before sending.
      await window.kaanch.sendKaanch(number, toAddress);
    } else {
      alert("The function window.kaanch.sendKaanch() is not available.");
    }
  };

  const sendToken = async () => {
    if (window.kaanch && window.kaanch.sendToken) {
      // You should validate 'number', 'contractAddress', and 'toAddress' here before sending.
      await window.kaanch.sendToken(number, toAddress, contractAddress);
    } else {
      alert("The function window.kaanch.sendToken() is not available.");
    }
  };

  const retrieveAptosAddress = async () => {
    if (window.aptos && window.aptos.connect) {
      const aptosData = await window.aptos.connect();
      setAptosAddress(aptosData.address);
    } else {
      alert("The function window.aptos.connect() is not available.");
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Test Dapp</h1>
        <button onClick={connectToMetaMask}>Connect to MetaMask</button>
        {account && <p>Connected Account: {account}</p>}

        <br></br>

        <button onClick={retrieveAddress}>connect to kaanch</button>
        {retrievedAddress && <p>Retrieved Address: {retrievedAddress}</p>}<br></br>

        <button onClick={checkIsConnected}>Check Is kaanch Connected</button>
        {isConnected && <p>Connected to Kaanch</p>}<br></br>

        <button onClick={retrieveKaanchAccount}>Retrieve Kaanch Account</button>
        {kaanchAcc && <p>Kaanch Account: {kaanchAcc}</p>}<br></br>

        <button onClick={checkaptosConnected}>Check Is aptos Connected</button>
        {isaptosConnected && <p>Connected to aptos</p>}<br></br>

        <button onClick={retrieveAptosAddress}>Retrieve Aptos Address</button>
        {aptosAddress && <p>Aptos Address: {aptosAddress}</p>}<br></br>

        <button onClick={disconnectFromKaanch}>Disconnect from Kaanch</button><br></br>

        <div>
          <h2>Send Kaanch</h2>
          <input
            type="number"
            placeholder="Amount"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
          <input
            type="text"
            placeholder="To Address"
            value={toAddress}
            onChange={(e) => setToAddress(e.target.value)}
          />
          <button onClick={sendKaanch}>Send Kaanch</button>
        </div>

        <div>
          <h2>Send Token</h2>
          <input
            type="number"
            placeholder="Amount"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
          <input
            type="text"
            placeholder="Contract Address"
            value={contractAddress}
            onChange={(e) => setContractAddress(e.target.value)}
          />
          <input
            type="text"
            placeholder="To Address"
            value={toAddress}
            onChange={(e) => setToAddress(e.target.value)}
          />
          <button onClick={sendToken}>Send Kaanch Token</button>
        </div>        
      </header>
    </div>
  );
}

export default App;